import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import ArticlesIntro from '../components/Articles/ArticlesIntro';
import ArticlesList from '../components/Articles/ArticlesList';
import LoadMore from '../components/Articles/LoadMore';
import Select from '../components/Select/Select';
import Container from '../components/Layout/SharedStyles/Container';
import FeaturedCardsComponent from '../components/Cards/FeaturedCardsComponent';

const ArticlesPages = ({
  data: {
    allDatoCmsArticle: { nodes },
    datoCmsArticlesPage: { title, id, metaTags, lead, pathways },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  const allPosts = nodes;
  const pagesize = 9;

  let categories = [];
  for (let i = 0; i < allPosts.length; i++) {
    let post = allPosts[i];
    let c = post.category.title;
    if (categories.indexOf(c) === -1) {
      categories.push(c);
    }
  }

  const [filteredPosts, setFilteredPosts] = useState(allPosts);
  const [list, setList] = useState([...allPosts.slice(0, pagesize)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(allPosts.length > pagesize);
  const [topic, setTopic] = useState('');

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < filteredPosts.length;
      const nextResults = isMore
        ? filteredPosts.slice(currentLength, currentLength + pagesize)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
      setHasMore(currentLength + nextResults.length < filteredPosts.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore]);

  useEffect(() => {
    let newFilteredPosts = allPosts.filter((post) => {
      let r = true;
      if (topic && post.category.title !== topic) {
        r = false;
      }
      return r;
    });
    setFilteredPosts(newFilteredPosts);
    setList([...newFilteredPosts.slice(0, pagesize)]);
    setHasMore(pagesize < newFilteredPosts.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <ArticlesIntro leadText={lead}>
        <Select
          items={categories}
          noSelectionLabel="All categories"
          getFilterSelect={(s) => {
            setTopic(s);
          }}
        />
      </ArticlesIntro>
      <Container pb={4}>
        {(!list || list.length === 0) && <p>No articles found</p>}
        {list && <ArticlesList items={list} />}
        {hasMore && <LoadMore onClick={handleLoadMore} />}
      </Container>
      <FeaturedCardsComponent pathways={pathways}></FeaturedCardsComponent>
    </PageWrapper>
  );
};

export default ArticlesPages;

export const query = graphql`
  query ArticlesPages($locale: String!, $id: String!) {
    datoCmsArticlesPage(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      title
      lead
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
      pathways {
        heading
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fm: "webp", h: "270", fit: "crop", w: "440", q: 60 }
          )
        }
        link {
          ...Link
        }
        subHeading
        text
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    allDatoCmsArticle(filter: { locale: { eq: $locale } }) {
      nodes {
        image {
          gatsbyImageData
        }
        excerpt
        category {
          title
        }
        title
        slug
        originalId
      }
    }
  }
`;
