import React from 'react';
import style from './articles-intro.mod.scss';
import Container from '../Layout/SharedStyles/Container';

const ArticlesIntro = ({ children, leadText }) => {
  return (
    <Container width={'medium'}>
      <div className={style.articlesintro}>
        {leadText && <div className={style.articlesintro__text}>
          <p>{leadText}</p>
        </div>}
        {children && <div className={style.articlesintro__content}>{children}</div>}
      </div>
    </Container>
  );
};
export default ArticlesIntro;
