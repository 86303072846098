import React from 'react';
import style from './loadmore.mod.scss';

const LoadMore = ({ onClick }) => {
  return (
    <div className={style.loadmore}>
      <button type="button" onClick={onClick}>Load more</button>
    </div>
  );
};

export default LoadMore;
