import React from 'react';
import ArticlesCard from './ArticlesCard';
import style from './list.mod.scss';

const ArticlesList = ({ items }) => {
  return (
    <div className={style.list}>
      {items && items.map((item) => (
        <ArticlesCard {...item} className={style.list__item} key={item.originalId} />
      ))}
    </div>
  );
};

export default ArticlesList;
