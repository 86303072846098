import React from 'react';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';
import style from './card.mod.scss';

const ArticlesCard = ({ className, title, category, showCategory, excerpt, image, buttonText = "read more", originalId }) => {
  return (
    <div className={cn(style.card, className)}>
      <div className={style.card__inner}>
        <div className={style.card__image}>
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
        </div>
        <div className={style.card__content}>
          {category && showCategory && <h6>{category.title}</h6>}
          <h4>{title}</h4>
          <div className={style.card__text}>{excerpt}</div>
          <Button recordId={originalId} primary className={style.card__link}>{buttonText}</Button>
        </div>
      </div>
    </div>
  );
};
export default ArticlesCard;
